.consultant_header{
    /* height: 60px; */
    /* height: 10vh; */
    border-radius: 5px;
    border: 1px solid #0000001A;
    box-shadow: 0px 1px 4px #0000001a;
    background: #FFFFFF;
    width: 100%;
}

.logo{
    width: 150px;
    padding: 10px;
  }

.permission-setting {
    position: absolute;
    width: 90%;
    left: 5%;
    font-size: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 5px;
    text-align: left;
    z-index: 20;
    overflow: scroll;
    height: 740px;
}

.fix-setting>h2 {
    border-bottom: 2px solid #0000007d;
    width: 100%;
}

.permission-setting>.heading {
    margin: 0;
}

.lock-icon {
    width: 22px;
    height: 22px;
}

.website-url {
    width: 200px;
    height: 50px;
}

.permission-steps {
    font-size: 16px;
}

.permission-sub-steps {
    font-size: 14px;
}

.loader-contianer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-img {
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.reloadForPermission {
    color: white;
    background: blue;
    border-radius: 5px;
    border: 1px solid blue;
    width: 250px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
}

.reloadPermissionDiv {
    text-align: center;
}
@font-face {
  font-family: proxima_nova_bold;
  src: url('./asset/proxima_nova_bold.ttf');
}

@font-face {
  font-family: proxima_nova_medium;
  src: url('./asset/proxima_nova_medium.otf');
}

html,
body {
  font-family: proxima_nova_medium !important;
  overflow: hidden;
  margin: unset;
}

body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

:root {
  --width: 320px;
}

@media(max-width: 320px) {
  :root {
    --width: 240px;
  }

  .miniVideo {
    top: unset !important;
  }

  .joinTime {
    font-size: 14px !important;
  }
}

.logo {
  width: 150px;
}

.headder {
  height: 60px;
  /* width: 360px; */
  width: 100%;
  border: 1px solid #0000001A;
  padding-top: 13px;
  box-shadow: 0px 1px 4px #0000001A;
}

.rightCircle {
  float: right;
}

.reload-page{
  border: none;
  padding: 8px 12px;
  background: #2196f39c;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

.content {
  margin-left: 20px;
  /* font-weight: 500; */
  color: #00263E;
  text-align: left;
}

.doctorName {
  font-weight: bold;
  color: #3D7CC9;
}

.startTime {
  color: #3D7CC9;
  font-weight: 500;
  font-size: 16px;
}

row {
  margin-right: unset;
}

col {
  margin-right: unset;
}

.joinVideo {
  color: white;
  background: #FF8300;
  border-radius: 5px;
  border: 1px solid #FF8300;
  width: 250px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.joinVideo:disabled {
  background: grey !important;
  border: grey !important;
}

.joinVideoDiv {
  text-align: center;
  margin-top: 80px;
}

.joinVideoDivDoc {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 25px;
}

.joinTime {
  color: #00263E;
  font-size: 16px;
  margin-top: 12px;
}

.messageDiv {
  /* margin-top: 98px; */
  margin-top: 5%;
}

.message {
  border: 1px solid #EAF1F9;
  border-radius: 5px;
  background-color: #EAF1F9;
  /* width: 320px; */
  width: var(--width);
  margin: auto;
  color: #3D7CC9;
  font-size: 14px;
  padding: 8px 10px;
}

.leftCircleDiv {
  position: absolute;
  bottom: 0;
}

.header{
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #0000001a;
  width: 100%;
}

.headder1 {
  height: 60px;
  /* width: 170px; */
  border-radius: 5px;
  border: 1px solid #0000001A;
  box-shadow: 0px 1px 4px #0000001a;
  background: #FFFFFF;
  width: 100%;
  /* z-index: 2; */
}

row {
  margin-right: unset;
}

col {
  margin-right: unset;
}

.footer {
  height: 70px;
  width: 100%;
  position: absolute;
  /* bottom: 0; */
  top: 0;
  background: white;
  text-align: center;
  /* z-index: 2; */
}

.callIcon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  /* margin-left: 50px; */
}

.footer .col:first-child .callIcon {
  margin-left: 40px;
}

col {
  padding-right: unset;
  padding-left: unset;
}

.row>* {
  /* padding-right: unset; */
  /* padding-left: unset; */
}

.row {
  margin-right: unset;
  margin-left: unset;
}

.footer .col {
  all: unset;
}

.buttonTxt {
  font-size: 12px;
  color: #666A70;
  /* padding-left: 40px; */
  /* padding-left: 34px; */
}

.messageDiv1 {
  /* margin-top: 98px; */
  position: absolute;
  margin-left: auto;
  bottom: 100px;
  width: 100%;
  text-align: center;
}

.message1 {
  border: 1px solid #EAF1F9;
  border-radius: 5px;
  background-color: #EAF1F9;
  /* width: 320px; */
  margin: auto;
  color: #3D7CC9;
  font-size: 14px;
  padding: 8px 10px;
  /* text-align: justify; */
  /* padding-left: 68px; */
  /* padding-right: 67px; */
  margin-left: 20px;
  margin-right: 20px;
  line-height: 16px;
}

.miniVideo {
  /* position: absolute; */
  /* border: 1px solid darkgray; */
  height: 40vh;
  width: 100%;
  /* top: 20.5px; */
  /* right: 23.5px; */
  /* background-image: url("demo.png"); */
  /* background: #707070; */
  background: #fafafa;
  /* -webkit-transform: translate3d(0,0,0); */
  /* z-index: 10; */
  /* float: right; */
}

.miniVideoPatient {
  position: absolute;
  /* border: 1px solid darkgray; */
  height: 200px;
  width: 136px;
  top: 82.5px;
  left: 20.5px;
  /* background-image: url("demo.png"); */
  background: #fafafa;
}

.miniVideoBD {
  position: absolute;
  /* border: 1px solid darkgray; */
  height: 200px!important;
  width: 136px;
  top: 82.5px;
  right: 23.5px;
  /* background-image: url("demo.png"); */
  background: #fafafa;
}

.miniVideoDoc {
  position: absolute;
  /* border: 1px solid darkgray; */
  height: 344px;
  width: 319.15px;
  top: 302px;
  right: 23.5px;
  /* background-image: url("demo.png"); */
  background: #fafafa;
}

.rateHead {
  font-size: 16px;
  color: #00263E;
  font-weight: 500;
  padding-left: 30px;
  margin-top: 20px;
}

.rateHead1 {
  font-size: 30px;
  color: #00263E;
  font-weight: 500;
  padding-left: 30px;
  margin-top: 20px;
}

.rateSub {
  font-size: 14px;
  color: #7A7A7A;
  padding-left: 30px;
}

.rateSub1 {
  font-size: 18px;
  color: #7A7A7A;
  padding-left: 20px;
}

.rateSub2 {
  font-size: 18px;
  color: #7A7A7A;
  padding-left: 1px;
  padding-right: 30px;
}

.emoje {
  text-align: center;
  margin-top: 20px;
}

.emoje img {
  height: 40px;
  width: 40px;
  margin-right: 15px;
  filter: grayscale(100%);
}

.txtArea {
  width: 90%;
  height: 80px;
  border: 1px solid #7A7A7A;
  border-radius: 10px;
  margin-top: 10px;
}

.submitFeedback {
  text-align: center;
  /* top: 670px; */
  /* position: absolute; */
  padding-top: 100px;
}

.submitFeedback1 {
  text-align: center;
  /* top: 670px; */
  /* position: absolute; */
  padding-top: 20px;
}

.reJoinButton {
  text-align: center;
  /* top: 670px; */
}

.submitFeedbackButt {
  color: white;
  background: #FF8300;
  border-radius: 5px;
  border: 1px solid #FF8300;
  width: 280px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.submitFeedbackButt:disabled {
  color: white;
  background: grey;
  border-radius: 5px;
  border: 1px solid grey;
  width: 280px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.rejoinButt {
  color: #FF8300;
  background: white;
  border-radius: 5px;
  border: 1px solid #FF8300;
  width: 280px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.content1 {
  height: 80px;
}

.headder1Div {
  /* height: 60px; */
}

.logo{
  width: 150px;
  padding: 10px;
}

.logo1 {
  width: 150px;
  padding: 10px;
}

.forDoctor {
  /* display: none; */
}

.forPatient {}

#screen-1 {
  display: none;
}

#screen1 {
  /* display: none; */
}

#screen2 {
  /* display: none; */
}

#screen3 {
  /* display: none; */
}

#screen4 {
  display: none;
}

#screen5 {
  display: none;
}

.disclaimer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 1%;
}

.disclaimer>span{
  font-size: 14px;
  margin-top: 2px;
}
@media screen and (max-width: 520px) {
  .disclaimer>span{
    font-size: 12px;
  }
}
.fullVideo {
  /* z-index: 0; */
  /* -webkit-transform: translate3d(0, 0, 0); */
  width: 100%;
  /* position: fixed; */
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* position: absolute; */
  /* height: 670px; */
  /* margin-top: 62px; */
  background: #fafafa;
  /* height: calc(100vh - 132px); */
  height: 40vh;
  /* background: #707070; */
}

.loader {
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#message1 {
  display: none;
}

.onVideoOff {
  right: 73.5px;
  position: absolute;
  top: 100.5px;
}

#onVideoOff {
  display: none;
}

.onAudioOff {
  right: 73.5px;
  position: absolute;
  top: 180.5px;
}

#onAudioOff {
  display: none;
}

.rightRadio {
  margin-left: 30px;
}

.inputNumber {
  width: 100%;
  height: 100%;
}

.inputSelect {
  width: 100%;
  height: 100%;
}

.formRow {
  margin-top: 15px;
}

.astrics {
  color: red;
  display: none;
}

.form-select {
  line-height: 1;
  margin-top: -5px;
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    text-align: center !important;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .content {
    text-align: center !important;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .content {
    text-align: center !important;
  }
}

.offVideoOn {
  right: 45%;
  position: absolute;
  top: 50%;
}

#offVideoOn {
  display: none;
}

.offAudioOn {
  left: 53%;
  position: absolute;
  top: 50%;
}

#offAudioOn {
  display: none;
}

.missingId {}

#myLoader {
  display: none;
}

#with {
  display: none;
}

#screen-no-internet {
  /* display: none; */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.noInternet {
  position: absolute;
  top: 35%;
  left: 21%;
  font-size: 20px;
  color: white;
  background: #0000004d;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}

#screen-slow-internet {
  position: absolute;
  height: 25px;
  background: blue;
  color: white;
  width: 100%;
  text-align: center;
}

.slowInternet {
  height: 25px;
}

#screen-no-permission {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 2; */
  cursor: pointer;
}

.noInternetIcon {
  height: 20px;
  margin: 0 5px 0 5px;
}

.noInternetAllow {
  color: white;
  /* background: #FF8300; */
  background: #ffffff52;
  border-radius: 5px;
  border: 1px solid #ffffff52;
  width: 250px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.noPermissionIcon {
  height: 30px;
  margin: 0 5px 0 5px;
}

.forBD {
  display: none;
}

#screen-low-battery {
  position: absolute;
  top: 170px;
  height: 281px;
  background: #FFF9E5;
  color: #00263E;
  width: 90%;
  text-align: center;
  margin-left: 5%;
}

.lowBattery {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 2; */
}

.pristyn-outline-button {
  border: 1px solid var(---ff8300);
  box-shadow: 0px 0px 8px #0000001A;
  border: 1px solid #FF8300;
  border-radius: 5px;
  color: #FF8300;
}

.footer div {
  padding-top: unset !important;
}

.permissionTxt {
  /* position: absolute; */
  /* bottom: 15%; */
  margin-top: 15px;
}

.permissionTxt1 {
  /* position: absolute; */
  /* bottom: 20%; */
  margin-top: 15px;
}

.exitCallButt {
  color: #FF8300;
  background: white;
  border-radius: 5px;
  border: 1px solid #FF8300;
  width: 140px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.exitCall {
  position: absolute;
  background: #FFF9E5;
  width: 85%;
  margin-left: 5%;
  text-align: center;
  padding: 10px;
  top: 35%;
  z-index: 10;
}

.exitCallHead {
  font-size: 16px;
  font-weight: bold;
}

.exitCallBody {
  font-size: 14px;
}

.disableOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000047;
  z-index: 5;
}

.noInternetNew {
  background-color: #fff;
  height: 100%;
  padding-top: 20%;
}

.header-corss-icon {
  display: flex;
  justify-content: end;
  padding: 15px;
}

.header-corss-icon>span>svg {
  width: 15px;
  height: 15px;
}

.img-part {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.img-part>span>svg {
  margin-top: 38px;
}

.footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-button button {
  background-color: #FF8300;
  color: #fff;
  width: 280px;
  height: 40px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  margin-top: 40px;
}

.text-container {
  display: flex;
  justify-content: center;
}

.text-container h2 {
  margin-top: 12px;
  color: #3D7CC9;
  font-size: 18px;
}

.second-text {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-text p {
  color: #00263E;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.lowBatteryBody {
  color: #00263E;
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
}

.lowBatteryHead {
  color: #3D7CC9;
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
}

.lowBatteryImg {
  margin-top: 30px;
}

.lowBatteryImg img {
  height: 80px;
}

.helloTxt {
  margin-bottom: 3%;
}

.docRejoin {
  /* position: absolute; */
  /* bottom: 10%; */
  width: 100%;
}

.logoFixed {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 5;
  background: #fff;
}

.infoTxt {
  /* width: 320px; */
  border-radius: 5px;
  background: rgb(255, 249, 229);
  /* height: 50px; */
  color: rgb(0, 38, 62);
  text-align: center;
  padding-top: 12px;
  font-size: 14px;
}

.infoTxtDiv {
  position: absolute;
  bottom: 90px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.infoTxtDiv1 {
  position: absolute;
  bottom: 150px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.profileImageDiv {
  height: 38vh;
  background: rgb(231, 233, 235);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 100px;
  margin: auto;
}

.impStar {
  color: red;
}

.screenShowSteps {
  position: absolute;
  width: 90%;
  /* top: 5%; */
  left: 5%;
  font-size: 20px;
  color: #fff;
  /* background: #fff; */
  border-radius: 10px;
  padding: 5px;
  text-align: left;
  z-index: 20;

  overflow: scroll;
  height: 740px;
}

.bdTimer {
  color: #3D7CC9;
  float: right;
  margin-right: 20px;
  margin-top: 15px;
}
.meeting-room{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.meeting_container{
    padding: 0 10px;
    height: inherit;
}

.remote_user{
    width: 100%;
    height: 100%;
    position: relative;
}

.remote_user_container{
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    height: 100%;
    margin-top: 5px;
}

.local_user{
    width: 100%;
    position: relative;
    height: inherit;
}

.remote_user, .local_user{
    max-width: 760px;
    margin-inline: auto;
}

.local_user_thumbnail{
    width: inherit;
    height: inherit;
    background: beige;
    border-radius: 16px;
    position: relative;
}

.remote_user_thumbnail{
    width: inherit;
    height: inherit;
    background: aliceblue;
    border-radius: 16px;
}

.user_initial{
    position: absolute;
    background: linear-gradient(to right, #80808057, #838b8bc9);
    margin: 0;
    top: 50%;
    color: white;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    padding: 32px;
    font-size: 26px;
}

.video-screen{
    width: inherit;
}

.local-video-screen{
    width: 100%;
    height: inherit;
}

.local-video-screen, .video-screen{
    object-fit: cover;
    border-radius: 15px;
}

.waiting_message{
    color: #666a70;
    top: 50%;
    position: absolute;
    left: 50%;
    margin: 0;
    transform: translate(-50%,-50%);
}

.controller-footer{
    justify-content: space-evenly;
    align-items: flex-end;
    background: antiquewhite;
    bottom: 0;
    position: absolute;
    width: 100%;
}

.icon-box{
    text-align: center;
    cursor: pointer;
    padding: 16px;
    width: fit-content;
}

.icon{
    width: 30px;
    height: 30px;
}

.icon-text{
    margin: 0;
    font-size: 12px;
    color: #666A70;
    margin-left: -12px;
    display: block;
}

.info-text{
    z-index: 1;
    margin: 0;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    color: rgb(0, 38, 62);
    margin-bottom: 23px;
    margin-left: -12px;    

    position: absolute;
    width: 100%;
    bottom: 70px;
    background: rgba(255, 249, 229, 0.8);
}

.remove_remote_user{
    background: white;
    width: 0;
    display: none;
}

.not_compatible_info{
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.not_compatible_info > .not_compatible_img{
    width: 300px;    
}

.not_compatible_info > .not_compatible_txt{
    font-size: 20px;
    color: #5a5a5a;
}

/* .browser_list{
    list-style: none;
} */

.resize_local_user{
    width: 22%;
    height: 30vh;
    animation-name: animate_local_user;
    animation-duration: 0.8s;
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 1;
}

.two_remote_user{
    width: 50%;
}

.attendee_mute_status{
    position: absolute;
    width: 30px;
    right: 8px;
    top: 8px;
    z-index: 1;
}

.notify_meeting_message{
    background: #ff8300;
    color: white;
    text-align: center;
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 60px;
    font-weight: bold;
}

.notify_meeting_message>p{
    margin: 10px;
}

@keyframes animate_local_user {
    from {
        width: 100%;
        height: 75vh;
    }
    to {
        width: 30%;
        height: 35vh;
    }
}

@media screen and (max-width: 520px) {
   .icon-text{
        width: 64px;
    }
    .remote_user_container{
         flex-direction: column;
         gap: 10px;
     }
 
     .remote_user{
         margin: 0;
         /* height: 49%; */
     }
 
    .two_remote_user{
         width: 100%;
         margin: 0;
     }
 
     .video-screen{
         margin: 0;
         /* height: 270px; */
     }

     .h_video{
        height: 270px;
     }

     .resize_local_user{
         width: 30%;
         height: 25vh;
     }
    .not_compatible_info> .not_compatible_txt{
        font-size: 16px;
    }
 }